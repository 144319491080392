<template>
  <div>
    <div v-if="parseInt(viewingRoom.image_right) === 1" class="outer-box">
      <div class="one-third-width text-center">
        <div class="artist">{{ viewingRoom.title }}</div>
      </div>
      <div class="two-thirds-width inner-space pt-5-small">
        <img
          :src="viewingRoom.uri"
          alt="sdl_installation_one" />
      </div>
    </div>
    <div v-else class="outer-box">
      <div class="two-thirds-width inner-space pt-5-small">
        <img
          :src="viewingRoom.uri"
          alt="sdl_installation_one" />
      </div>
      <div class="one-third-width text-center">
        <div class="artist">{{ viewingRoom.title }}</div>
      </div>
    </div>
    <div
      v-for="(block, index) in viewingRoom.blocks"
      :key="index">
      <app-block-one-render
        v-if="block.block_type === 1"
        :contents="block"
        :type="'live'" />
      <app-block-two-render
        v-if="block.block_type === 2"
        :contents="block"
        :type="'live'" />
      <app-block-three-render
        v-if="block.block_type === 3"
        :contents="block"
        :type="'live'" />
      <app-block-four-render
        v-if="block.block_type === 4"
        :contents="block"
        :type="'live'" />
      <app-block-five-render
        v-if="block.block_type === 5"
        :contents="block"
        :type="'live'" />
      <app-block-six-render
        v-if="block.block_type === 6"
        :contents="block"
        :type="'live'" />
      <app-block-seven-render
        v-if="block.block_type === 7"
        :contents="block"
        :type="'live'" />
    </div>
  </div>
</template>

<script>
import blockOneRender from '@/components/BlockOneRender.vue';
import blockTwoRender from '@/components/BlockTwoRender.vue';
import blockThreeRender from '@/components/BlockThreeRender.vue';
import blockFourRender from '@/components/BlockFourRender.vue';
import blockFiveRender from '@/components/BlockFiveRender.vue';
import blockSixRender from '@/components/BlockSixRender.vue';
import blockSevenRender from '@/components/BlockSevenRender.vue';
import axios from '../axios';

export default {
  name: 'RoomMackrill',
  data() {
    return {
      viewingRoom: {
        image_position: 0,
      },
    };
  },
  components: {
    appBlockOneRender: blockOneRender,
    appBlockTwoRender: blockTwoRender,
    appBlockThreeRender: blockThreeRender,
    appBlockFourRender: blockFourRender,
    appBlockFiveRender: blockFiveRender,
    appBlockSixRender: blockSixRender,
    appBlockSevenRender: blockSevenRender,
  },
  methods: {
    getRoom() {
      const roomId = this.$route.params.id;
      axios.get(`/viewingRooms/getRoom/${roomId}.json`)
        .then((response) => {
          this.viewingRoom = response.data;
          if (this.viewingRoom.image_right === true) this.viewingRoom.image_right = 1;
          if (this.viewingRoom.image_right === false) this.viewingRoom.image_right = 0;
        });
    },
    handleScroll() {
      const foundElements = document.querySelectorAll('.bounceup');
      foundElements.forEach((element) => {
        if (this.isInViewport(element)) {
          element.classList.add('visible');
        }
      });
    },
    isInViewport(element) {
      const distance = element.getBoundingClientRect();
      const elementHeight = element.clientHeight;
      const toBottom = (distance.bottom - elementHeight);
      let isVisible = false;
      if (
        toBottom < (window.innerHeight)
        && distance.top >= -elementHeight
      ) {
        isVisible = true;
      }
      return isVisible;
    },
  },
  mounted() {
    this.getRoom();
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
